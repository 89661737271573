import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { PrivateRoute } from 'components/PrivateRoute'
import { AppRoutes } from 'constants/appRoutes'

import { AccountsPage } from '../AccountsPage'
import { AddressesPage } from '../AddressesPage'
import { CasesPage } from '../CasesPage'
import { CreateAdminPage } from '../CreateAdminPage'
import { ERPAddressIDsPage } from '../ERPAddressIDsPage'
import { ITeroPage } from '../ITeroPage'
import { LoginPage } from '../LoginPage'
import { NavPage } from '../NavPage'
import { PVSPage } from '../PVSPage'
import { ScannersPage } from '../ScannersPage'
import { ScansPage } from '../ScansPage'
import { SupportsPage } from '../SupportsPage'
import { TransferPage } from '../TransferPage'
import { UsersPage } from '../UsersPage'
import { WebContentPage } from '../WebContentPage'

export const AppRouter = () => {
  return (
    <Routes>
      <Route path={AppRoutes.login} element={<LoginPage />} />

      <Route path={AppRoutes.root} element={<PrivateRoute />}>
        <Route path="" element={<NavPage />} />
      </Route>

      <Route path={AppRoutes.cases} element={<PrivateRoute />}>
        <Route path="" element={<CasesPage />} />
      </Route>

      <Route path={AppRoutes.scans} element={<PrivateRoute />}>
        <Route path="" element={<ScansPage />} />
      </Route>

      <Route path={AppRoutes.users} element={<PrivateRoute />}>
        <Route path="" element={<UsersPage />} />
        <Route path=":userId" element={<UsersPage />} />
      </Route>

      <Route path={AppRoutes.accounts} element={<PrivateRoute />}>
        <Route path="" element={<AccountsPage />} />
      </Route>

      <Route path={AppRoutes.scanners} element={<PrivateRoute />}>
        <Route path="" element={<ScannersPage />} />
      </Route>

      <Route path={AppRoutes.supports} element={<PrivateRoute />}>
        <Route path="" element={<SupportsPage />} />
      </Route>

      <Route path={AppRoutes.itero} element={<PrivateRoute />}>
        <Route path="" element={<ITeroPage />} />
      </Route>

      <Route path={AppRoutes.webcontent} element={<PrivateRoute />}>
        <Route path="" element={<WebContentPage />} />
      </Route>

      <Route path={AppRoutes.pvs} element={<PrivateRoute />}>
        <Route path="" element={<PVSPage />} />
      </Route>

      <Route path={AppRoutes.addresses} element={<PrivateRoute />}>
        <Route path="" element={<AddressesPage />} />
      </Route>

      <Route path={AppRoutes.transfer} element={<PrivateRoute />}>
        <Route path="" element={<TransferPage />} />
      </Route>

      <Route path={AppRoutes.erpAddressIDs} element={<PrivateRoute />}>
        <Route path="" element={<ERPAddressIDsPage />} />
      </Route>

      <Route path={AppRoutes.createAdmin} element={<PrivateRoute />}>
        <Route path="" element={<CreateAdminPage />} />
      </Route>
    </Routes>
  )
}
