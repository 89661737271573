import { FileResponse } from 'types/APIResponses'

import { protectedApi } from './requester'

export const getFileCasesList = async (caseId: number) => {
  return protectedApi.get<string[]>(`/file/list/Cases/${caseId}`).then((response) => response.data)
}

export const getFilePreSignUrl = async (caseIdKey: number) => {
  return protectedApi.get<string>(`/file/get/Cases/${caseIdKey}`).then((response) => response.data)
}

export const getFilePutCases = async (caseIdKey: number) => {
  return protectedApi
    .get<FileResponse>(`/file/put/Cases/${caseIdKey}`)
    .then((response) => response.data)
}
