import React, { useContext, useState } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'
import { ProductInfo } from 'domains/productInfo'
import { StandardErrorResponse } from 'types/APIResponses'
import { isNotNil } from 'utils/isNotNil'

import { filterProducts } from './utils'

export const CaseProduct = () => {
  const { casesPageState } = useContext(CasesPageContext)
  const { id, productInfoId } = casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [productInfo, setProductInfo] = useState(productInfoId)
  const handleChangeProductInfo = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setProductInfo(event.target.value as typeof ProductInfo[keyof typeof ProductInfo])

  const handleUpdateProduct = () => {
    if (!productInfo) {
      return
    }

    API.updateCaseById({ id, productInfoId: productInfo })
      .then(() => requestStatusRef.current?.showAlert('Updated', 'success'))
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const isProductChangeDisabled =
    productInfoId == ProductInfo.ODB ||
    productInfoId == ProductInfo['Spark Passive Aligners'] ||
    productInfoId == ProductInfo.Retainers ||
    productInfoId == ProductInfo['Spark Junior Phase 2']

  return (
    <tr>
      <td>Product</td>
      <td>
        {isNotNil(productInfoId) && (
          <form>
            <select
              name="productInfoId"
              value={productInfo}
              onChange={handleChangeProductInfo}
              className="w-50"
              disabled={isProductChangeDisabled}>
              {Object.entries(ProductInfo)
                .filter(([_, value]) => filterProducts(productInfoId, value))
                .map(([key, value], index) => (
                  <option
                    key={`${value}-${index}`}
                    value={value}
                    disabled={value === ProductInfo.ODB}>
                    {key}
                  </option>
                ))}
            </select>
            <button
              onClick={handleUpdateProduct}
              disabled={isProductChangeDisabled}
              className="float-right"
              type="button">
              Update
            </button>
          </form>
        )}
      </td>
    </tr>
  )
}
