import React, { useContext, useState } from 'react'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers/RequestToasterProvider'
import { StandardErrorResponse } from 'types/APIResponses'

interface AccountFeaturePermissionsProps {
  username: string
  initPermissions: string
}

export const AccountFeaturePermissions = ({
  username,
  initPermissions,
}: AccountFeaturePermissionsProps) => {
  const [permissions, setPermissions] = useState(initPermissions)
  const handleChangePermissions = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setPermissions(event.target.value)

  const { requestStatusRef } = useContext(RequestToasterContext)

  const handleUpdatePermissions = async () => {
    try {
      API.changeAccountPermissions({ username, permissions })
        .then(() => requestStatusRef.current?.showAlert('Updated', 'success'))
        .catch((error: StandardErrorResponse) => {
          requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
        })
    } catch (error) {
      requestStatusRef.current?.showAlert('Error in permission json format', 'danger')
    }
  }

  return (
    <tr>
      <td>Feature Permissions</td>
      <td>
        <textarea
          name="permissions"
          value={permissions}
          onChange={handleChangePermissions}
          cols={31}
          rows={8}
        />
        <button onClick={handleUpdatePermissions} className="float-right">
          Update
        </button>
      </td>
    </tr>
  )
}
