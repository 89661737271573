export const AppRoutes = {
  root: '/',
  login: '/login',
  cases: '/cases',
  scans: '/scans',
  users: '/users',
  accounts: '/accounts',
  scanners: '/scanners',
  supports: '/supports',
  itero: '/itero',
  webcontent: '/webcontent',
  pvs: '/pvs',
  transfer: '/transfer',
  addresses: '/addresses',
  erpAddressIDs: '/erpAddressIDs',
  createAdmin: '/create-admin',
} as const

export type AppRoutes = typeof AppRoutes[keyof typeof AppRoutes]
