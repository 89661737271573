import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate, useParams, createSearchParams } from 'react-router-dom'
import { Container, Table } from 'reactstrap'

import { API } from 'API'
import { RequestStatus } from 'components/RequestStatus'
import { AppRoutes } from 'constants/appRoutes'
import { RequestToasterContext } from 'containers/Providers'
import { FeatureFlags } from 'domains/featureFlags'
import { User } from 'domains/user'
import { StandardErrorResponse } from 'types/APIResponses'
import { Nullable } from 'types/utility'
import { isNotNil } from 'utils/isNotNil'

import { MatchItem, UserInfo } from './components'

const USERS_SEARCH_STRING = 'search'
const USER_PARAMS = { userId: 'userId' } as const

export const UsersPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [search, setSearch] = useState(searchParams.get(USERS_SEARCH_STRING) ?? '')
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)

  const [matches, setMatches] = useState<User[]>([])
  const [username, setUsername] = useState(params[USER_PARAMS.userId] ?? null)
  const [user, setUser] = useState<Nullable<User>>(null)
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({})

  useEffect(() => {
    if (isNotNil(username)) {
      handleGet()
    }
  }, [username])

  useEffect(() => {
    if (username == null && search) {
      handleGetMatches()
    }
  }, [])

  const handleRedirectToUser = (matchUsername: string) => {
    setUsername(matchUsername)
    setSearch('')
  }
  const clearMatches = () => {
    setUsername(null)
    setMatches([])
  }
  const handlePressSearchKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      navigate(AppRoutes.users)
      event.preventDefault()
      handleGetMatches()
    }
  }
  const handleGetMatches = () => {
    if (!search) {
      clearMatches()
      return
    }
    if (user) {
      setUser(null)
    }

    navigate({
      pathname: AppRoutes.users,
      search: `${createSearchParams({ [USERS_SEARCH_STRING]: search })}`,
    })

    requestStatusRef.current?.startProgress('Searching users...', 'secondary')
    API.getUsersList(encodeURIComponent(search))
      .then((response) => {
        if (response.length === 0) {
          clearMatches()
          requestStatusRef.current?.showAlert('Not Found', 'danger')
        } else if (response.length === 1) {
          const { username: responseUsername } = response[0]
          setMatches([])
          setUsername(responseUsername)
          navigate(`${AppRoutes.users}/${responseUsername}`)
          requestStatusRef.current?.showAlert('User found', 'success')
        } else {
          setMatches(response)
          setUsername(null)
          requestStatusRef.current?.showAlert('Users found', 'success')
        }
      })
      .catch((error: StandardErrorResponse) => {
        clearMatches()
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleGet = () => {
    if (username == null) {
      clearMatches()
      return
    }
    requestStatusRef.current?.startProgress('Getting user info...', 'secondary')
    API.getUser(encodeURIComponent(username))
      .then(({ user, featureFlags }) => {
        setUser(user)
        setFeatureFlags(featureFlags)
        setMatches([])
        navigate(`${AppRoutes.users}/${user.username}`)
        requestStatusRef.current?.showAlert('Done', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        clearMatches()
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleNavigateToCreateAdmin = () => {
    navigate(AppRoutes.createAdmin)
  }

  return (
    <Container>
      <RequestStatus />
      <h2>Manage users</h2>
      <form className="w-75 d-flex mb-1">
        <input
          name="search"
          value={search}
          onChange={handleSearch}
          onKeyDown={handlePressSearchKey}
          placeholder="search by username, first or last name, or role: admin/operator/doctor"
          className="flex-grow-1"
        />
        <button onClick={handleGetMatches} className="ml-1 mr-1" type="button">
          Search
        </button>
        <button onClick={handleNavigateToCreateAdmin} type="button">
          Create Admin User
        </button>
      </form>
      <div
        className="w-auto border p-2 mt-4 bg-light small"
        style={{ position: 'absolute', top: '5em', right: '1em' }}>
        <h6>By name:</h6>
        <ul>
          <li>{'<name>'}</li>
          <li>{'<first> <last>'}</li>
        </ul>
        <h6>By role and name:</h6>
        <ul>
          <li>{'<role> <name>'}</li>
        </ul>
        <h6>Roles:</h6>
        <ul>
          <li>admin</li>
          <li>doctor</li>
          <li>operator</li>
          <li>scanner, scan</li>
          <li>anatomy</li>
          <li>designer, design</li>
          <li>designqc, qc</li>
          <li>shipper, ship</li>
          <li>manufacturing, mfg</li>
        </ul>
      </div>
      {matches.length !== 0 && !user && (
        <Table bordered className="w-75">
          <thead>
            <tr>
              <th>username</th>
              <th>role</th>
              <th>name</th>
            </tr>
          </thead>
          <tbody>
            {matches.map((item, index) => (
              <MatchItem key={index} user={item} onRedirectToUser={handleRedirectToUser} />
            ))}
          </tbody>
        </Table>
      )}
      {isNotNil(user) && <UserInfo user={user} featureFlags={featureFlags} getUser={handleGet} />}
    </Container>
  )
}
